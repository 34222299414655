import axios from 'axios';
import Component from '../../classes/Component';
import GSAP from 'gsap';

export default class ContactForm extends Component {
	constructor() {
		super({
			element: '.contact',
			elements: {
				form: '.contact__form',
				left: '.contact__left',
			},
		});
	}

	create() {
		super.createComponent();
		console.log(this.elements.form);
		console.log(this.elements.left);
		// Fix Element Left using GSAP Scroll trigger
		this.fixElementLeft();
	}

	fixElementLeft() {
		GSAP.to(this.elements.left, {
			scrollTrigger: {
				trigger: this.elements.left,
				start: 'top top',
				end: 'center top',
				pin: true,
				pinSpacing: false,
			},
		});
	}

	animate(e) {}

	submitForm(e) {
		e.preventDefault();
		this.animate('loading');

		// Get data from form and add it to an object
		const finalData = {};
		const formData = new FormData(this.elements.form);

		[...formData].map((info) => {
			finalData[info[0]] = info[1];
		});

		console.log(finalData);

		// Send the object via axios
		this.sendData(finalData);
	}

	async sendData(data) {
		try {
			await axios.post(`${FN_URL}/submitform`, data);
			this.animate('success');
		} catch (error) {
			console.error(error.message);
			this.animate('error');
		}
	}

	addEventListeners() {
		this.elements.form.addEventListener('submit', (e) => this.submitForm(e));
	}

	removeEventListeners() {
		this.elements.form.removeEventListener('submit', (e) => this.submitForm(e));
	}

	destroy() {
		GSAP.killTweensOf(this.elements.left);
	}
}
