export default {
	basic: {
		mainHeader: 'Basic',
		mainDescription:
			'A smart, budget-friendly choice for those who aim for simplicity and impact. Offering a well-designed single-page website.',
		mainPrice: {
			number: '$2,100',
		},
		services: [
			{ title: 'Web Development', desc: 'Single page', disabled: false },
			{ title: 'Graphic Design', desc: 'Web Design', disabled: false },
			{ title: 'Brand Identity', desc: 'Brand Sheet', disabled: false },
			{ title: 'SEO', desc: 'Basic SEO Setup', disabled: false },
			{ title: 'Maintenance', desc: 'Basic Support', disabled: false },
			{ title: 'Analytics', desc: 'Basic Analytics Setup', disabled: true },
			{ title: 'CMS', desc: 'Basic CMS', disabled: true },
			{ title: 'E-commerce', desc: 'Not Available', disabled: true },
		],
		servicesDescription: `Offering a well-designed single-page website and graphic design services, this plan is perfect for showcasing a product, launching a campaign, or making an announcement online. It's a concise yet powerful tool for carving out your niche in the digital sphere.`,
	},
	standard: {
		mainHeader: 'Standard',
		mainDescription:
			'An ideal plan for individuals or small businesses seeking a straightforward, effective way to establish their online presence.',
		mainPrice: {
			number: '$2,440',
		},
		services: [
			{ title: 'Web Development', desc: 'Up to 5 pages', disabled: false },
			{
				title: 'Graphic Design',
				desc: 'Web Design & Brand Identity',
				disabled: false,
			},
			{
				title: 'Brand Identity',
				desc: 'Logotype & Brand Book',
				disabled: false,
			},
			{ title: 'SEO', desc: 'Standard SEO Optimization', disabled: false },
			{ title: 'Maintenance', desc: 'Standard Support', disabled: false },
			{
				title: 'Analytics',
				desc: 'Advanced Analytics with Custom Dashboards',
				disabled: false,
			},
			{ title: 'CMS', desc: 'Custom CMS Features', disabled: true },
			{
				title: 'E-commerce',
				desc: 'Not Included',
				disabled: true,
			},
		],
		servicesDescription: `This plan provides a solid foundation for those who are new to the online space. It's perfect for showcasing your product or service on a sleek, user-friendly website. Ideal for individuals or small businesses seeking a straightforward, effective way to establish their online presence.`,
	},
	pro: {
		mainHeader: 'Business',
		mainDescription:
			'Is your business ready to level up? This plan is a perfect choice for those aiming to significantly broaden their reach and influence online.',
		mainPrice: {
			number: '$2,750',
		},
		services: [
			{ title: 'Web Development', desc: 'Unlimited pages', disabled: false },
			{ title: 'Graphic Design', desc: 'UI/UX & Motion', disabled: false },
			{
				title: 'Brand Identity',
				desc: 'Full Visual Identity',
				disabled: false,
			},
			{ title: 'SEO', desc: 'Advanced SEO Strategies', disabled: false },
			{ title: 'Maintenance', desc: 'Comprehensive Support', disabled: false },
			{
				title: 'Analytics',
				desc: 'Comprehensive Analytics with Conversion Tracking',
				disabled: false,
			},
			{ title: 'CMS', desc: 'Fully Customized CMS', disabled: false },
			{
				title: 'E-commerce',
				desc: 'Full-fledged E-commerce Solutions',
				disabled: false,
			},
		],
		servicesDescription: `Is your business ready to level up? From advanced web development to full-fledged graphic design and brand identity, this plan is a perfect choice for those aiming to significantly broaden their reach and influence online.`,
	},
};
